const AR_BASE_HEADERS = {
  ArchireportOrigin: "archireportweb",
  ArchireportContext: "AR",
  AppVersion: "6.0.0",
};

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

export const getPublicMessage = async (accessToken, objParams) => {
  const baseAPIUrl =
    window.localStorage.getItem("apiUrl") || "https://api.archireport.com";

  const getParams = new URLSearchParams(objParams);
  const response = await fetchWithTimeout(
    `${baseAPIUrl}/publicmessage/infos/${accessToken}?${getParams}`,
    {
      timeout: 20000,
      headers: AR_BASE_HEADERS,
    }
  );

  const data = await response.json();

  if (response.status > 300) {
    throw data;
  }

  return data || {};
};

const getRegionList = async (env) => {
  try {
    const response = await fetchWithTimeout(
      `https://which.regions-api.archireport.com/region-list?env=${env}`,
      {
        timeout: 6000,
        headers: AR_BASE_HEADERS,
      }
    );
    return response.json() || [];
  } catch (error) {
    console.log("error calling which.regions-api", error);

    // backup regions
    return [
      {
        continent: "europe",
        region: "eu-west-3",
        title: "Web_Region_Europe",
        short_title: "EU",
        web: "https://web.archireport.com",
        api: "https://api.archireport.com",
        s3_bucket: "ar-accounts-prod",
      },
      {
        continent: "north-america",
        region: "us-east-1",
        title: "Web_Region_NorthAmerica",
        short_title: "US",
        web: "https://web.archireport.com",
        api: "https://us-api.archireport.com",
        s3_bucket: "ar-us-accounts",
      },
      {
        continent: "oceania",
        region: "ap-southeast-2",
        title: "Web_Region_Oceania",
        short_title: "AU",
        web: "https://web.archireport.com",
        api: "https://au-api.archireport.com",
        s3_bucket: "ar-au-accounts",
      },
    ];
  }
};

const safeShortenRegion = (region) => {
  if (region.startsWith("eu")) {
    return "eu";
  } else if (region.startsWith("us")) {
    return "us";
  } else if (region.startsWith("ap")) {
    return "au";
  }

  return region;
};

export const saveRegionUrlInfos = async (env, region) => {
  const regionList = await getRegionList(env);

  const { api, web } = regionList.find((regionObj) => {
    return safeShortenRegion(regionObj.region) === safeShortenRegion(region);
  });

  return { api, web };
};
